import React from 'react';
import Slider from 'react-slick';

import cliqueDesigns from '../../../../images/testimonials/cliqueDesigns.png';
import dibberIndia from '../../../../images/testimonials/dibberIndia.png';
import nuvedo from '../../../../images/testimonials/nuvedo.png';

const renderTest = (test) => (
  <div className='text-center px-6 py-12 md:py-12 md:px-32' key={test.id}>
    <div className='block m-auto w-40 h-40'>
      <img src={test.image} alt={test.description} className="border border-os-orange-light bg-os-orange-light rounded-full" />
    </div>
    <div className="font-['JostLight'] text-xl md:text-2xl text-center">
      <div className="py-8">
        <q>{test.description}</q>
      </div>
      <div className='text-[#FA5C18]'>{test.author}</div>
    </div>
  </div>
);

const Testimonials = () => {
  const testimonialsList = [
    {
      "id": 1,
      "description": "Outstrip has been a valuable asset for Clique Designs, providing comprehensive analytical reports that have greatly enhanced our ability to monitor and optimize our clientele's social media growth. Their user-friendly interface and insightful data have proven invaluable in driving our agency's success.",
      "author": "Narmada, Clique Designs",
      "image": cliqueDesigns,
    },
    {
      "id": 2,
      "description": "We no longer take screenshots from any consoles. All our social media reports are automated in just seconds.",
      "author": "Arun, Dibber India",
      "image": dibberIndia,
    },
    {
      "id": 3,
      "description": "Outstrip is a handy tool that gets your customized social media reports at your finger tips.",
      "author": "Sreekanth, Nuvedo",
      "image": nuvedo,
    }
  ]

  const settings = {
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className='w-4/5 m-auto'>
      <Slider {...settings}>
        {testimonialsList.map(renderTest)}
      </Slider>
    </div>
  );
}

export default Testimonials;
