import React from 'react';

import FilesImg from '../../../../images/we-value-privacy/files.png';
import LockImg from '../../../../images/we-value-privacy/lock.png';
import UparrowImg from '../../../../images/we-value-privacy/uparrow.png';

const WeValuePrivacy = () => {

  const privacyPoints = [
    {
      id: 1,
      icon: FilesImg,
      description: `We are legally compliant with the terms of META, YouTube and Google.`,
    },
    {
      id: 2,
      icon: LockImg,
      description: `Our policies are also integrated with the Digital India Act 2023 to store and process data.`,
    },
    {
      id: 3,
      icon: UparrowImg,
      description: `You will also get access to all the future updates and upgrades without extra cost.`,
    }
  ]

  const renderPrivacyPoint = (point) => {
    return (
      <div key={point.id} className='flex items-start mt-4'>
        <div className='min-w-10 min-h-10'>
          <img className='w-10 h-10' src={point.icon} />
        </div>
        <div className='pl-6 pr-10'>{point.description}</div>
      </div>
    );
  }

  const renderContent = () => {
    return <div className='md:grid grid-flow-cols grid-cols-3 mr-6'>{privacyPoints.map(renderPrivacyPoint)}</div>;
  }

  return (
    <div className='p-10 md:pt-20 md:pb-36 md:px-14 md:ml-36'>
      <h1 className='text-4xl md:text-8xl text-gradient'>We Value Privacy</h1>
      <div className='text-xl md:text-3xl my-6 md:my-12'>….as much as you value your followers</div>
      {renderContent()}
    </div>
  );
}

export default WeValuePrivacy;
